* {
  font-family: "Raleway", sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th {
  background-color: #6c0000;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

td {
  border: 1px solid #ddd;
  color: #666;
  font-size: 14px;
  padding: 10px;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

input[type="text"] {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 300px;
  margin-bottom: 20px;
}

button {
  padding: 5px 10px;
  background-color: #6c0000;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #6c0000;
}

.hd-title {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 10vmin;
  pointer-events: none;
}

.logo-div {
  text-align: center;
  background-color: #6c0000;
  padding: 10px 0;
  top: 0;
}

.paginate-div {
  text-align: center;
  padding-bottom: 20px;
}

.table-div {
  padding: 0 30px;
}

.search-table {
  text-align: center;
}
