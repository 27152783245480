body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stats-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-value {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #666;
}

/* Media queries */
@media only screen and (max-width: 768px) {
  .stats-div {
    flex-direction: column;
    margin-top: 1rem;
  }

  .stat {
    margin-bottom: 1rem;
  }

  .stat-value {
    font-size: 2rem;
  }

  .stat-label {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 480px) {
  .stat-value {
    font-size: 1.5rem;
  }

  .stat-label {
    font-size: 0.7rem;
  }
}
